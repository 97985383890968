"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeClientTypeChecker = initializeClientTypeChecker;
class ClientTypeChecker {
    constructor(config) {
        this.config = config;
        this.timedOut = false;
        this.img = new Image();
        this.timer = null;
        // Handle successful image load (internal client)
        this.handleImageLoad = () => {
            if (!this.timedOut) {
                this.clearTimer();
                this.setInternalClient();
            }
        };
        // Handle image load error (external client)
        this.handleImageError = () => {
            if (!this.timedOut) {
                this.clearTimer();
                this.setExternalClient();
            }
        };
        this.initializeCheck();
    }
    // Initialize the client type check
    initializeCheck() {
        this.setTimer();
        this.loadImage();
    }
    // Set a timeout for image loading
    setTimer() {
        this.timer = window.setTimeout(() => {
            this.timedOut = true;
            this.cancelImageLoad();
            this.setExternalClient();
        }, this.config.checkTimeout);
    }
    // Load the image and handle success or failure
    loadImage() {
        this.img.onload = this.handleImageLoad;
        this.img.onerror = this.handleImageError;
        this.img.src = this.config.checkUrl;
    }
    // Clear the timer if image loads or errors before timeout
    clearTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }
    // Cancel image loading on timeout
    cancelImageLoad() {
        this.img.src = ''; // Cancel image loading
    }
    // Mark as internal client
    setInternalClient() {
        console.log('Internal client');
        document.body.classList.add(this.config.successClass);
    }
    // Mark as external client
    setExternalClient() {
        document.body.classList.add(this.config.failedClass);
        this.applyDomainRestrictions();
    }
    // Log messages only if DevTools is open
    log(message) {
        console.log(message);
    }
    // Apply domain restrictions to domains in the domain list
    applyDomainRestrictions() {
        this.config.domains.forEach(domain => {
            const elements = document.querySelectorAll(`a[href*="${domain}"]`);
            console.log(elements);
            elements.forEach(element => {
                element.classList.add('broken-link-detector-link-is-unavabile');
                element.setAttribute("data-tooltip", this.config.tooltip);
                element.addEventListener("click", (event) => {
                    event.preventDefault();
                });
            });
        });
    }
}
// @ts-ignore Function to initialize client type checker  
function initializeClientTypeChecker(brokenLinkContextDetectionData) {
    document.addEventListener("DOMContentLoaded", () => {
        new ClientTypeChecker(brokenLinkContextDetectionData);
    });
}
// @ts-ignore
initializeClientTypeChecker(brokenLinkContextDetectionData);
